import { REFRESH_NIGHTBOT_TOKENS } from 'root/src/shared/descriptions/endpoints/endpointIds'

import cronJobPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/cronJobPayloadSchema'

export default {
	[REFRESH_NIGHTBOT_TOKENS]: {
		payloadSchema: cronJobPayloadSchema,
		isCron: true,
	},
}
