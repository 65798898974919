import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses';
import { dropEndpointIds } from 'root/src/shared/drops/drops.constants';
export const getDropWatchTimePredictionEndpoint = {
    [dropEndpointIds.GET_DROP_WATCH_TIME_PREDICTION]: {
        endpointType: otherEndpointType,
        payloadSchema: {
            type: 'object',
            properties: {
                dropId: { type: 'string' },
            },
            required: ['dropId'],
        },
    },
};
