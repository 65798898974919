import { LAMBDA_HEALTH_CHECK_CRON } from 'root/src/shared/descriptions/endpoints/endpointIds'

import cronJobPayloadSchema
	from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/cronJobPayloadSchema'

export default {
	[LAMBDA_HEALTH_CHECK_CRON]: {
		payloadSchema: cronJobPayloadSchema,
		isCron: true,
	},
}
