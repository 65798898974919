import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth';
import { pointsEndpointIds } from 'root/src/shared/points/points.constants';
export const updateCampaignTermsAcceptedEndpoint = {
    [pointsEndpointIds.UPDATE_CAMPAIGN_TERMS_ACCEPTED]: {
        authentication: authenticationTypes.authenticated,
        authenticationRole: userGroups.admin,
        payloadSchema: {
            type: 'object',
            properties: {
                dropId: { type: 'string' },
                creatorId: { type: 'string' },
                update: {
                    type: 'object',
                    properties: {
                        estimatedPointsToEarn: { type: 'number' },
                        reservedPointsLeft: { type: 'number' },
                        reservedPointsTotal: { type: 'number' },
                        pointsReservationDueDate: { type: 'string' },
                    },
                },
            },
            required: ['dropId', 'creatorId'],
        },
    },
};
