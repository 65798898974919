import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth';
import { pointsEndpointIds } from 'root/src/shared/points/points.constants';
export const getCampaignTermsAcceptedEndpoint = {
    [pointsEndpointIds.GET_CAMPAIGN_TERMS_ACCEPTED]: {
        authentication: authenticationTypes.authenticated,
        authenticationRole: userGroups.admin,
        payloadSchema: {
            type: 'object',
            properties: {
                dropId: { type: 'string' },
                creatorId: { type: 'string' },
            },
        },
        required: ['dropId', 'creatorId'],
    },
};
