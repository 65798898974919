import { DROP_EVENT_PAGE_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import { dropEvent } from 'root/src/shared/descriptions/endpoints/recordTypes'
import { dropEndpointIds } from 'root/src/shared/drops/drops.constants'

export default {
	[DROP_EVENT_PAGE_MODULE_ID]: {
		moduleType: 'record',
		recordPageType: dropEvent,
		endpointId: [dropEndpointIds.DROP_GET_EVENT, dropEndpointIds.GET_USER_CAMPAIGN_DATA],
		recordPayloadMap: [
			['recordId', { $sub: ['recordId'] }],
		],
		redirectOn404: true,
	},
}
