import { creatorConstants } from 'root/src/shared/creator/creator.constants';
export const collectCreatorDataEndpoint = {
    [creatorConstants.COLLECT_CREATOR_DATA]: {
        payloadSchema: {
            type: 'object',
            properties: {
                creators: {
                    type: 'array',
                    items: {
                        type: 'object',
                        properties: {
                            platform: { type: 'string' },
                            platformId: { type: 'string' },
                        },
                        required: ['platform', 'platformId'],
                    },
                },
            },
            required: ['creators'],
        },
    },
};
