import cronJobPayloadSchema
	from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/cronJobPayloadSchema'
import { UPDATE_DELIVERY_RATIO } from 'root/src/shared/descriptions/endpoints/endpointIds'

export default {
	[UPDATE_DELIVERY_RATIO]: {
		payloadSchema: cronJobPayloadSchema,
		isCron: true,
	},
}
