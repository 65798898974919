import apiRequest from 'root/src/client/logic/api/thunks/apiRequest'
import displayModal from 'root/src/client/logic/modal/thunks/displayModal'
import { brandProductTypesEnum } from 'root/src/shared/constants/dropEvent'
import { GET_BRAND_CODES } from 'root/src/shared/descriptions/endpoints/endpointIds'
import editCodesPayloadSchema from 'root/src/shared/descriptions/formSchemas/editBrandCodesSchema'
import { dropUploadCodesFields } from 'root/src/shared/descriptions/modules/dropUploadCodesForm'
import { BRAND_CODE_LIST_MODULE_ID, EDIT_BRAND_CODES_MODAL_MODULE_ID, SUCCESS_MODAL_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import { rewardEndpointIds } from 'root/src/shared/rewards/constants'

export default {
	[EDIT_BRAND_CODES_MODAL_MODULE_ID]: {
		moduleType: 'modal',
		schema: editCodesPayloadSchema,
		modalContent: {
			title: 'Edit codes',
			fields: dropUploadCodesFields,
			submits: [
				{
					label: 'Save',
					buttonType: 'primaryButton',
					onClick: item => async (dispatch) => {
						const { error } = await dispatch(apiRequest(rewardEndpointIds.EDIT_BRAND_REWARDS, {
							rewardData: item,
							rewardType: brandProductTypesEnum.dropCode,
						}))
						if (error) {
							dispatch(displayModal(SUCCESS_MODAL_MODULE_ID, {
								header: error.code,
								errorText: error.message,
								infoText: '',
							}))
						} else {
							dispatch(displayModal(undefined))
						}
						return dispatch(apiRequest(GET_BRAND_CODES, {}, true, BRAND_CODE_LIST_MODULE_ID))
					},
					htmlType: 'submit',
				},
			],
		},
	},
}
