import { demoDashboardEndpointIds } from 'root/src/shared/demoDashboard/demoDashboard.constants';
import { getDashboardCreatorsEndpoint } from 'root/src/server/modules/dashboard/endpoints/getDashboardCreators.endpoint';
import { getDashboardDeliveriesEndpoint } from 'root/src/server/modules/dashboard/endpoints/getDashboardDeliveries.endpoint';
import { getDashboardChartsDataEndpoint } from 'root/src/server/modules/dashboard/endpoints/getDashboardChartsData.endpoint';
import { dropGetEventEndpoint } from 'root/src/server/modules/drops/endpoints/dropGetEvent.endpoint';
import { dashboardConstants } from 'root/src/shared/dashboard/dashboard.constants';
import { dropEndpointIds } from 'root/src/shared/drops/drops.constants';
export const demoDashboardEndpoints = {
    [demoDashboardEndpointIds.DEMO_GET_DASHBOARD_CREATORS]: getDashboardCreatorsEndpoint[dashboardConstants.GET_DASHBOARD_CREATORS],
    [demoDashboardEndpointIds.DEMO_GET_DASHBOARD_DELIVERIES]: getDashboardDeliveriesEndpoint[dashboardConstants.GET_DASHBOARD_DELIVERIES],
    [demoDashboardEndpointIds.DEMO_GET_DASHBOARD_CHARTS_DATA]: getDashboardChartsDataEndpoint[dashboardConstants.GET_DASHBOARD_CHARTS_DATA],
    [demoDashboardEndpointIds.DEMO_GET_DASHBOARD_DROP_EVENT]: dropGetEventEndpoint[dropEndpointIds.DROP_GET_EVENT],
};
