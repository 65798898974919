import { TWITCH_CHAT_SEND_CYCLIC_MESSAGE } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { recordEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { creatorData } from 'root/src/shared/descriptions/endpoints/recordTypes'
import cronJobPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/cronJobPayloadSchema'

export default {
	[TWITCH_CHAT_SEND_CYCLIC_MESSAGE]: {
		endpointType: recordEndpointType,
		recordType: creatorData,
		payloadSchema: cronJobPayloadSchema,
		isCron: true,
	},
}
