import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth';
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses';
export const getRewardsEndpoint = {
    authentication: authenticationTypes.authenticated,
    authenticationRole: userGroups.admin,
    endpointType: otherEndpointType,
    responseSchema: {
        type: 'array',
        items: {
            type: 'object',
            properties: {
                id: { type: 'string' },
                name: { type: 'string' },
                type: { type: 'string' },
                brand: {
                    type: 'object',
                    properties: {
                        name: { type: 'string' },
                        website: { type: 'string' },
                        email: { type: 'string' },
                        id: { type: 'string' },
                    },
                },
                region: { type: 'string' },
                platform: { type: 'string' },
                typeReadable: { type: 'string' },
                available: { type: 'number' },
                used: { type: 'number' },
            },
            additionalProperties: false,
            required: [
                'id',
                'name',
                'type',
                'brand',
                'available',
                'used',
            ],
        },
    },
};
