import { rewardEndpointIds } from 'root/src/shared/rewards/constants';
import { addBrandCustomRewardEndpoint } from 'root/src/server/modules/rewards/endpoints/addBrandCustomReward.endpoint';
import { editBrandRewardsEndpoint } from 'root/src/server/modules/rewards/endpoints/editBrandRewards.endpoint';
import { getBrandCustomRewardsEndpoint } from 'root/src/server/modules/rewards/endpoints/getBrandCustomRewards.endpoint';
import { getBrandRewardsEndpoint } from 'root/src/server/modules/rewards/endpoints/getBrandRewards.endpoint';
import { getCreatorInitialRewardsEndpoint } from 'root/src/server/modules/rewards/endpoints/getCreatorInitialRewards';
import { getDropKeyRewardsEndpoint } from 'root/src/server/modules/rewards/endpoints/getDropKeyRewards.endpoint';
import { getRewardEndpoint } from 'root/src/server/modules/rewards/endpoints/getReward.endpoint';
import { getRewardsEndpoint } from 'root/src/server/modules/rewards/endpoints/getRewards.endpoint';
import { getRewardsForCreatorEndpoint } from 'root/src/server/modules/rewards/endpoints/getCreatorRewards.endpoint';
import { saveCodeRewardsEndpoint } from 'root/src/server/modules/rewards/endpoints/saveCodeRewards.endpoint';
import { saveKeyRewardsEndpoint } from 'root/src/server/modules/rewards/endpoints/saveKeyRewards.endpoint';
import { useRewardsEndpoint } from 'root/src/server/modules/rewards/endpoints/useRewards.endpoint';
import { setDropDeliveredRewardsUsedEndpoint } from 'root/src/server/modules/rewards/endpoints/setDropDeliveredRewardsUsed.endpoint';
import { getRewardsForViewersEndpoint } from 'root/src/server/modules/rewards/endpoints/getViewersRewards.endpoint';
import { getDecryptedRewardEndpoint } from 'root/src/server/modules/rewards/endpoints/getDecryptedReward.endpoint';
import { transferRewardsEndpoint } from 'root/src/server/modules/rewards/endpoints/transferRewards.endpoint';
import { saveRewardsBulkEndpoint } from 'root/src/server/modules/rewards/endpoints/saveRewardsBulkEndpoint';
import { createBundleEndpoint } from 'root/src/server/modules/rewards/endpoints/createBundle.endpoint';
import { getBrandBundlesEndpoint } from 'root/src/server/modules/rewards/endpoints/getBrandBundles.endpoint';
export const rewardEndpoints = {
    // Fetch single reward by rewardId & brandId
    [rewardEndpointIds.GET_REWARD]: getRewardEndpoint,
    // Fetch all rewards from all brands
    [rewardEndpointIds.GET_REWARDS]: getRewardsEndpoint,
    // Fetch rewards by Brand
    [rewardEndpointIds.GET_BRAND_REWARDS]: getBrandRewardsEndpoint,
    // Fetch custom reward by Brand
    [rewardEndpointIds.GET_BRAND_CUSTOM_REWARDS]: getBrandCustomRewardsEndpoint,
    // Add Brand reward
    [rewardEndpointIds.ADD_BRAND_CUSTOM_REWARD]: addBrandCustomRewardEndpoint,
    // Edit Brand rewards
    [rewardEndpointIds.EDIT_BRAND_REWARDS]: editBrandRewardsEndpoint,
    // Fetch Creator rewards
    [rewardEndpointIds.GET_REWARDS_FOR_CREATOR]: getRewardsForCreatorEndpoint,
    // Fetch viewers rewards
    [rewardEndpointIds.GET_REWARDS_FOR_VIEWERS]: getRewardsForViewersEndpoint,
    // Fetch Key Rewards of a given Drop
    [rewardEndpointIds.GET_DROP_KEY_REWARDS]: getDropKeyRewardsEndpoint,
    // Use certain amount of rewards
    [rewardEndpointIds.USE_REWARDS]: useRewardsEndpoint,
    // Process codes from uploaded file
    [rewardEndpointIds.SAVE_CODE_REWARDS]: saveCodeRewardsEndpoint,
    // Process keys from uploaded file
    [rewardEndpointIds.SAVE_KEY_REWARDS]: saveKeyRewardsEndpoint,
    // Fetch all initial rewards for creator
    [rewardEndpointIds.GET_CREATOR_INITIAL_REWARDS]: getCreatorInitialRewardsEndpoint,
    // Edit Creator rewards
    [rewardEndpointIds.SET_DROP_DELIVERED_REWARDS_USED]: setDropDeliveredRewardsUsedEndpoint,
    // Fetch reward (returns decrypted data)
    [rewardEndpointIds.GET_DECRYPTED_REWARD]: getDecryptedRewardEndpoint,
    [rewardEndpointIds.TRANSFER_REWARDS]: transferRewardsEndpoint,
    [rewardEndpointIds.SAVE_REWARDS_BULK]: saveRewardsBulkEndpoint,
    [rewardEndpointIds.CREATE_BUNDLE]: createBundleEndpoint,
    [rewardEndpointIds.GET_BRAND_BUNDLES]: getBrandBundlesEndpoint,
};
