import { authenticationTypes, userGroups, } from 'root/src/shared/constants/auth';
import { listEndpointType } from 'root/src/shared/descriptions/endpoints/lenses';
import { creator } from 'root/src/shared/descriptions/endpoints/recordTypes';
import { getDashboardPayloadSchema } from 'root/src/shared/dashboard/schemas/getDashboardPayload.schema';
import { dashboardConstants } from 'root/src/shared/dashboard/dashboard.constants';
const responseSchema = {
    type: 'object',
    properties: {
        data: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    mnemonicId: { type: 'string' },
                    platform: { type: 'string' },
                    minutesStreamed: { type: 'number' },
                    minutesViewed: { type: 'integer' },
                    engagementCount: { type: 'integer' },
                    engagementRate: { type: 'number' },
                    followerCount: { type: 'integer' },
                    averageViewers: { type: 'integer' },
                    durationMinutes: { type: 'integer' },
                    viewCount: { type: 'integer' },
                    likeCount: { type: 'integer' },
                    shareCount: { type: 'integer' },
                    commentCount: { type: 'integer' },
                    streamsPostsCount: { type: 'integer' },
                    daresCompletedCount: { type: 'integer' },
                    clicks: { type: 'integer' },
                    displayName: { type: 'string' },
                    displayImage: { type: 'string' },
                    maxViewers: { type: 'integer' },
                },
            },
        },
        totals: {
            total: { type: 'integer' },
            followerCount: { type: 'number' },
            hoursStreamed: { type: 'number' },
            streamsPostsCount: { type: 'number' },
            clicks: { type: 'number' },
            hoursViewed: { type: 'number' },
            viewCount: { type: 'number' },
            ccv: { type: 'number' },
            maxViewers: { type: 'integer' },
        },
        currentPage: { type: 'integer' },
        allPage: { type: 'integer' },
    },
    required: ['data', 'totals'],
};
export const getDashboardCreatorsEndpoint = {
    [dashboardConstants.GET_DASHBOARD_CREATORS]: {
        endpointType: listEndpointType,
        recordType: creator,
        authentication: authenticationTypes.authenticated,
        authenticationRole: userGroups.brandUser,
        payloadSchema: getDashboardPayloadSchema,
        responseSchema,
    },
};
