import values from 'ramda/src/values';
import { GamingPlatform, KeyType } from 'root/src/shared/rewards/constants';
export const saveRewardKeysPayloadSchema = {
    type: 'object',
    properties: {
        game: {
            type: 'object',
            properties: {
                id: { type: ['string', 'integer'] },
            },
            required: ['id'],
        },
        filename: { type: 'string' },
        keyType: {
            type: 'string',
            enum: values(KeyType),
        },
        platform: {
            type: 'string',
            enum: values(GamingPlatform),
        },
        region: { type: 'string' },
        shortUrl: { type: 'string' },
        restrictions: {
            type: 'string',
            maxLength: 80,
        },
        id: { type: 'string' },
        dlcName: { type: 'string' },
    },
    required: ['filename', 'game', 'keyType', 'platform', 'region'],
    additionalProperties: false,
};
