import { shortUrlEndpointIds } from 'root/src/server/modules/shortUrl/shortUrl.fixture';
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth';
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses';
export const createShortUrlEndpoint = {
    [shortUrlEndpointIds.CREATE_SHORT_URL]: {
        authentication: authenticationTypes.authenticated,
        authenticationRole: userGroups.admin,
        endpointType: otherEndpointType,
        payload: {
            type: 'object',
            properties: {
                alias: {
                    type: 'string',
                },
                url: {
                    type: 'string',
                },
            },
            required: ['url'],
        },
    },
};
