import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth';
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses';
import { RewardType } from 'root/src/shared/rewards/constants';
export const getRewardEndpoint = {
    authentication: authenticationTypes.authenticated,
    authenticationRole: userGroups.admin,
    endpointType: otherEndpointType,
    responseSchema: {
        type: 'object',
        properties: {
            id: { type: 'string' },
            available: { type: 'number' },
            type: { type: 'string' },
            brandId: { type: 'string' },
        },
        required: ['id', 'available', 'type', 'brandId'],
        additionalProperties: false,
    },
    payloadSchema: {
        type: 'object',
        properties: {
            rewardId: { type: 'string' },
            brandId: { type: 'string' },
            rewardType: {
                type: 'string',
                enum: [
                    RewardType.KEY,
                    RewardType.CODE,
                    RewardType.CUSTOM,
                    RewardType.BUNDLE,
                ],
            },
        },
        required: ['rewardId', 'rewardType', 'brandId'],
        additionalProperties: false,
    },
};
