export const getBrandRewardsResponseSchema = {
    type: 'array',
    items: {
        properties: {
            id: { type: 'string' },
            name: { type: 'string' },
            cta: { type: 'string' },
            url: { type: 'string' },
            created: { type: 'string' },
            waiting: { type: 'integer' },
            used: { type: 'integer' },
        },
    },
};
