import { GET_CREATORS_WHO_ONBOARDING_STUCK } from 'root/src/shared/descriptions/endpoints/endpointIds'
import cronJobPayloadSchema
	from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/cronJobPayloadSchema'

export default {
	[GET_CREATORS_WHO_ONBOARDING_STUCK]: {
		payloadSchema: cronJobPayloadSchema,
		isCron: true,
	},
}
