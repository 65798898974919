import { DARE_EXPIRATION_ACTIONS_RUNNER } from 'root/src/shared/descriptions/endpoints/endpointIds'
import cronJobPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/cronJobPayloadSchema'

export const payloadSchema = cronJobPayloadSchema

export default {
	[DARE_EXPIRATION_ACTIONS_RUNNER]: {
		isCron: true,
		payloadSchema,
	},
}
