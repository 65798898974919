export const dropKeyRewardsSchema = {
    type: 'array',
    items: {
        type: 'object',
        properties: {
            game: {
                type: 'object',
                properties: {
                    id: { type: 'string' },
                },
                required: ['id'],
            },
            id: { type: 'string' },
            created: { type: 'string' },
            type: { type: 'string' },
            available: { type: 'integer' },
            used: { type: 'integer' },
            waiting: { type: 'integer' },
            name: { type: 'string' },
            region: { type: 'string' },
            restrictions: { type: 'string' },
        },
        required: ['game', 'id', 'created', 'type', 'available', 'name', 'region'],
    },
    additionalProperties: false,
};
