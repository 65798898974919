import { DROP_PREVIEW_EVENT_PAGE_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import { dropPreviewEvent } from 'root/src/shared/descriptions/endpoints/recordTypes'
import { dropEndpointIds } from 'root/src/shared/drops/drops.constants'

export default {
	[DROP_PREVIEW_EVENT_PAGE_MODULE_ID]: {
		moduleType: 'record',
		recordPageType: dropPreviewEvent,
		endpointId: [dropEndpointIds.DROP_GET_EVENT],
		redirectOn404: true,
		recordPayloadMap: [
			['recordId', { $sub: ['recordId'] }],
		],
	},
}
