import { giveawayEndpointIds } from 'root/src/shared/giveaways/giveaways.constants';
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses';
import { authenticationTypes, userGroups, } from 'root/src/shared/constants/auth';
import { giveawaySchedulePayloadSchema } from 'root/src/server/modules/giveaways/endpoints/create-giveaway.endpoint';
export const createGiveawayScheduleEndpoint = {
    [giveawayEndpointIds.CREATE_GIVEAWAY_SCHEDULE]: {
        authentication: authenticationTypes.authenticated,
        authenticationRole: userGroups.creator,
        endpointType: otherEndpointType,
        payloadSchema: giveawaySchedulePayloadSchema,
        isBeta: true,
    },
};
