import { SEND_MONTHLY_INVOICE } from 'root/src/shared/descriptions/endpoints/endpointIds'
import cronJobPayloadSchema
	from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/cronJobPayloadSchema'

export default {
	[SEND_MONTHLY_INVOICE]: {
		payloadSchema: cronJobPayloadSchema,
		isCron: true,
	},
}
