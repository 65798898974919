import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth';
import { pointsEndpointIds } from 'root/src/shared/points/points.constants';
export const MAX_CREATE_POINTS_APPLICATION_LENGTH = 400;
export const createPointsApplicationEndpoint = {
    [pointsEndpointIds.CREATE_POINTS_APPLICATION]: {
        authentication: authenticationTypes.authenticated,
        authenticationRole: userGroups.creator,
        payloadSchema: {
            type: 'object',
            properties: {
                dropId: { type: 'string' },
                comment: {
                    type: 'string',
                    maxLength: MAX_CREATE_POINTS_APPLICATION_LENGTH,
                    errorMessage: {
                        maxLength: 'Your comment can be at most 400 characters.',
                    },
                },
            },
            required: ['dropId'],
            additionalProperties: false,
        },
    },
};
