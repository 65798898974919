import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses';
import { dropEndpointIds } from 'root/src/shared/drops/drops.constants';
export const getDropMediaEndpoint = {
    [dropEndpointIds.GET_DROP_MEDIA]: {
        endpointType: otherEndpointType,
        payloadSchema: {
            type: 'object',
            properties: {
                dropMnemonicId: { type: 'string' },
            },
            required: ['dropMnemonicId'],
        },
    },
};
