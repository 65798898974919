export const obsCloudfrontDistributionArn = 'E17IINAIFD1N4J'
export const mixpanelProxyApiId = '727ucsa2uf'
export const apiLambdaLowSpecArn = 'arn:aws:lambda:us-east-1:685756249129:function:dareMeproduction-dareMeProductionCloudwatchLowSpec-rdapTLdJdcAP'
export const cloudWatchLogsReportErrorGroupName = 'dareMeproduction-dareMeProductionReportErrorGroup-1MBSX2BKPHIDV'
export const nextOriginFunctionName = 'dareMeproduction-dareMeProductionNextOriginRequest-28TAZIP1Axse'
export const lambdaSqsFifoQueueDlqUrl = 'https://sqs.us-east-1.amazonaws.com/685756249129/dareMeproduction-dareMeProductionLambdaSqsFifoQueueDlq-deMFSWcFP7J8.fifo'
export const deliveryUploadsQueueUrl = 'https://sqs.us-east-1.amazonaws.com/685756249129/PythonTasksStackProduction-DeliveryUloadsQueue02BA1BE6-KbjMq8joFXaK'
export const cloudWatchLogsInfoGroupName = 'dareMeproduction-dareMeProductionInfoLogGroup-EZNW6G6PKC2R'
export const apiCloudwatchFunctionArn = 'arn:aws:lambda:us-east-1:685756249129:function:dareMeproduction-dareMeProductionApiLambdaCloudWat-10FNFNWYBRPBP'
export const dataBucket = 'daremeproduction-daremeproductiondatabucket-18avdglefbli5'
export const dynamoLockTableName = 'arn:aws:dynamodb:us-east-1:685756249129:table/dareMeproduction-dareMeProductionLockTable-1KRFUARQX1TCY'
export const staticBucket = 'daremeproduction-daremeproductionstaticbucket-fb1g29xurmqi'
export const cloudfrontDistributionArn = 'E3BGR8WJW133ZQ'
export const cloudWatchLogsEsGroupName = 'dareMeproduction-dareMeProductionEsLogGroup-ec49CAGXkVJV'
export const clientId = '6uggp343v79nmn02j7jekj2fba'
export const elasticsearchDomainEndpoint = 'search-daremep-dareme-1vzt4xnkcb5kd-afsztum3zjy6dbfa5rfkyagb3q.us-east-1.es.amazonaws.com'
export const apiDynamoWebsocketsConnectionsTable = 'arn:aws:dynamodb:us-east-1:685756249129:table/dareMeproduction-dareMeProductionApiDynamoDbWebsocketConnectionsTable-12MCTXP88K8WY'
export const cloudwatchLogsOutput = 'daremeproduction-daremeproductioncloudwatchlogsbu-r5fhnefw9o6s'
export const apiDynamoArchivalTableName = 'arn:aws:dynamodb:us-east-1:685756249129:table/dareMeproduction-dareMeProductionApiDynamoDbArchivalTable-1CZKCKBBI0RPF'
export const gatewayWebsocketApiId = 'hpzpvrtn5i'
export const apiDynamoScanTableName = 'arn:aws:dynamodb:us-east-1:685756249129:table/dareMeproduction-dareMeProductionApiDynamoDbScanTable-1UCCZWNTNB4RU'
export const lambdaSqsStandardQueueUrl = 'https://sqs.us-east-1.amazonaws.com/685756249129/dareMeproduction-dareMeProductionLambdaSqsStandardQueue-ZI9XG3CDEI92'
export const lambdaSqsStandardQueueDlqUrl = 'https://sqs.us-east-1.amazonaws.com/685756249129/dareMeproduction-dareMeProductionLambdaSqsStandardQueueDlq-TQBNP6h5KW4x'
export const sesConfigurationSetEventBasedEmailsArn = 'dareMeProductionSESConfigurationSetEventBasedEmails-6brGHUZDcTfL'
export const apiDynamoTableName = 'arn:aws:dynamodb:us-east-1:685756249129:table/dareMeproduction-dareMeProductionApiDynamoDbTable-1LTV7M7W0XP9E'
export const trainDropWatchTimePredictionQueueUrl = 'https://sqs.us-east-1.amazonaws.com/685756249129/PythonTasksStackProduction-WatchTimePredictionQueue1D3B17BA-8SeGNuNPeh1w'
export const gatewaySentryTunnelApiId = 'cd8chkczdc'
export const cloudWatchLogsEmailGroupName = 'dareMeproduction-dareMeProductionEmailLogGroup-GvmdrTywtw8e'
export const apiFunctionArn = 'arn:aws:lambda:us-east-1:685756249129:function:dareMeproduction-dareMeProductionTriggerLambdaFunc-t5pHkoJO25fP'
export const fileUploadBucket = 'daremeproduction-daremeproductionfileuploadbucket-1r2tanej8p38r'
export const domainName = 'daredrop.com'
export const kmsCustomerMasterKeyArn = 'arn:aws:kms:us-east-1:685756249129:key/e17300dc-27bc-40c9-9abf-5d9cd5509f2a'
export const apiDynamoStatisticsTableName = 'arn:aws:dynamodb:us-east-1:685756249129:table/dareMeproduction-dareMeProductionApiDynamoDbStatisticsTable-1KXY6NT97LW6I'
export const gatewayLambdaArn = 'arn:aws:lambda:us-east-1:685756249129:function:dareMeproduction-dareMeProductionGatewayLambda-1SXT0SEDKDBQS'
export const lambdaSqsFifoQueueUrl = 'https://sqs.us-east-1.amazonaws.com/685756249129/dareMeproduction-dareMeProductionLambdaSqsFifoQueue-9952534IWONI.fifo'
export const userPoolId = 'us-east-1_5lxi78vfL'
export const identityPoolId = 'us-east-1:c5eff21a-60df-4ec3-a791-08da8a4b9e4c'
export const cloudWatchEventsIamRole = 'arn:aws:iam::685756249129:role/dareMeproduction-dareMeProductionCloudwatchEventsR-127HOJ92010GF'