import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth';
import { listEndpointType } from 'root/src/shared/descriptions/endpoints/lenses';
import { brandProduct } from 'root/src/shared/descriptions/endpoints/recordTypes';
import { VALIDATE_TOKEN_ACCOUNT } from 'root/src/shared/descriptions/middlewares/middlewareIds';
import { getBrandRewardsPayloadSchema } from 'root/src/shared/rewards/schemas/getBrandRewards.payload.schema';
import { getBrandRewardsResponseSchema } from 'root/src/shared/rewards/schemas/getBrandRewards.response.schema';
export const getBrandCustomRewardsEndpoint = {
    authentication: authenticationTypes.authenticated,
    authenticationRole: userGroups.brandUser,
    endpointType: listEndpointType,
    recordType: brandProduct,
    payloadSchema: getBrandRewardsPayloadSchema,
    responseSchema: getBrandRewardsResponseSchema,
    middlewares: [VALIDATE_TOKEN_ACCOUNT],
};
