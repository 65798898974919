import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth';
import { recordEndpointType } from 'root/src/shared/descriptions/endpoints/lenses';
import { project } from 'root/src/shared/descriptions/endpoints/recordTypes';
import projectResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/projectResponseSchema';
import { projectsEndpointIds } from 'root/src/shared/projects/projects.constants';
export const getProjectAdminEndpoint = {
    [projectsEndpointIds.GET_PROJECT_ADMIN]: {
        authentication: authenticationTypes.authenticated,
        authenticationRole: userGroups.admin,
        endpointType: recordEndpointType,
        recordType: project,
        payloadSchema: {
            type: 'object',
            properties: {
                projectUrl: { type: 'string' },
                projectId: { type: 'string' },
                isTwitchPanel: { type: 'boolean' },
            },
            required: [],
            additionalProperties: false,
        },
        responseSchema: projectResponseSchema,
    },
};
