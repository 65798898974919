import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses';
import { authenticationTypes } from 'root/src/shared/constants/auth';
import { VALIDATE_TOKEN_ACCOUNT } from 'root/src/shared/descriptions/middlewares/middlewareIds';
export const setDropDeliveredRewardsUsedEndpoint = {
    authentication: authenticationTypes.authenticated,
    endpointType: otherEndpointType,
    payloadSchema: {
        type: 'object',
        properties: {
            records: { type: 'array' },
        },
        required: ['records'],
        additionalProperties: false,
    },
    middlewares: [VALIDATE_TOKEN_ACCOUNT],
};
