import { CLEANUP_DB_BACKUPS } from 'root/src/shared/descriptions/endpoints/endpointIds'
import cronJobPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/cronJobPayloadSchema'

export const payloadSchema = cronJobPayloadSchema

export default {
	[CLEANUP_DB_BACKUPS]: {
		isCron: true,
		payloadSchema,
	},
}
