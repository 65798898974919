export const addBrandCustomRewardPayloadSchema = {
    type: 'object',
    properties: {
        name: {
            type: 'string',
            maxLength: 55,
            errorMessage: {
                maxLength: 'max: 55 characters',
            },
        },
        cta: {
            type: 'string',
            maxLength: 25,
            errorMessage: {
                required: 'CTA is required',
                maxLength: 'max: 25 characters',
            },
        },
        url: { type: 'string' },
        restrictions: {
            type: 'string',
            maxLength: 80,
        },
    },
    additionalProperties: false,
    required: ['name', 'cta', 'url'],
};
