import { tableListType } from 'root/src/shared/constants/moduleTypes'
import { initialProduct } from 'root/src/shared/descriptions/endpoints/recordTypes'
import { MY_INITIAL_PRODUCTS_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import { rewardEndpointIds } from '../../rewards/constants'

export default {
	[MY_INITIAL_PRODUCTS_MODULE_ID]: {
		moduleType: 'list',
		listType: tableListType,
		recordType: initialProduct, // TODO: rename globally
		headerType: 'backgroundDesign',
		endpointId: [rewardEndpointIds.GET_CREATOR_INITIAL_REWARDS],
		defaultListDataLength: 10,
		isClientPaginated: true,
		listModuleId: MY_INITIAL_PRODUCTS_MODULE_ID,
		constantListStoreKey: true,
		listTitle: 'Keys & Codes for Me',
		toggleVisibilityProp: [1],
		tableColumns: [
			['Game', 'gameName'],
			['Product', 'rewards', items => items.map((item) => {
				if (item.name) {
					return `${item.name}: ${item.rewardData}`
				}
				return item.rewardData
			}).join('\n')],
		],
		redirectOn403: true,
	},
}
