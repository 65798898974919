import { listEndpointType } from 'root/src/shared/descriptions/endpoints/lenses';
import { creatorReward } from 'root/src/shared/descriptions/endpoints/recordTypes';
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth';
import emptyPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/emptyPayloadSchema';
export const getRewardsForCreatorEndpoint = {
    authentication: authenticationTypes.authenticated,
    authenticationRole: userGroups.creator,
    endpointType: listEndpointType,
    recordType: creatorReward,
    payloadSchema: emptyPayloadSchema,
    // TODO: missing response schema
};
