export const getBrandRewardsPayloadSchema = {
    type: 'object',
    properties: {
        limit: { type: 'integer' },
        section: { type: 'string' },
        brandId: { type: 'string' },
    },
    required: [],
    additionalProperties: false,
};
