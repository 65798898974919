import { DARE_DELIVERY_DETAIL_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import { APPROVE_DELIVERY, REJECT_DELIVERY } from 'root/src/shared/descriptions/recordClickActions/recordClickActionIds'
import { projectsEndpointIds } from 'root/src/shared/projects/projects.constants'

export default {
	[DARE_DELIVERY_DETAIL_MODULE_ID]: {
		moduleType: 'record',
		recordPageType: 'reviewProject',
		endpointId: projectsEndpointIds.GET_PROJECT_ADMIN,
		recordPayloadMap: [
			['projectUrl', { $sub: ['recordId'] }],
		],
		recordClickActions: [APPROVE_DELIVERY, REJECT_DELIVERY],
	},
}
