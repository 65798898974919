import assoc from 'ramda/src/assoc'
import dissocPath from 'ramda/src/dissocPath'
import equals from 'ramda/src/equals'
import find from 'ramda/src/find'
import includes from 'ramda/src/includes'
import insert from 'ramda/src/insert'
import is from 'ramda/src/is'
import isNil from 'ramda/src/isNil'
import lensProp from 'ramda/src/lensProp'
import map from 'ramda/src/map'
import pipe from 'ramda/src/pipe'
import prepend from 'ramda/src/prepend'
import prop from 'ramda/src/prop'
import propOr from 'ramda/src/propOr'
import view from 'ramda/src/view'
import when from 'ramda/src/when'
import without from 'ramda/src/without'
import { addTrack } from 'root/src/client/logic/analytics/pixel/facebookPixel'
import getTokens from 'root/src/client/logic/api/selectors/tokensSelector'
import userGroupsSelector from 'root/src/client/logic/auth/selectors/userGroupsSelector'
import { formStoreLenses } from 'root/src/client/logic/form/lenses'
import autoPopulateDare from 'root/src/client/logic/form/thunks/autoPopulateDare'
import myProfileOnSuccessRedirectSelector from 'root/src/client/logic/profile/selectors/myProfileOnSuccessRedirectSelector'
import { outlinedButton, primaryButton, secondaryButton } from 'root/src/client/web/componentTypes'
import { assigneesKey, userLiveEventsKey } from 'root/src/shared/constants/keys'
import paymentTypes from 'root/src/shared/constants/paymentTypes'
import { youtubeSearchDebounce } from 'root/src/shared/constants/youtube'
import { CREATE_PROJECT, CREATE_PROJECT_PRODUCT, GET_DROP_EVENTS } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { paymentMethod } from 'root/src/shared/descriptions/endpoints/recordTypes'
import createProjectPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/createProjectPayloadSchema'
import { acceptProjectCommon } from 'root/src/shared/descriptions/modules/claimProjectForm'
import {
	CREATE_DARE_SKIP_PLEDGE_SUCCESS_MODULE_ID,
	CREATE_PROJECT_FORM_MODULE_ID, DARE_CREATE_SUCCESS_MODULE_ID,
	SELF_DARE_CREATE_SUCCESS_MODULE_ID,
} from 'root/src/shared/descriptions/modules/moduleIds'
import { formCommon, multiWayField, productCommonFields } from 'root/src/shared/descriptions/modules/pledgeProjectForm'
import {
	BRAND_DARES_ROUTE_ID,
	VIEW_CREATOR_DATA_ROUTE_ID,
	VIEW_FACEBOOK_CREATOR_DATA_ROUTE_ID,
	VIEW_TIKTOK_CREATOR_DATA_ROUTE_ID,
	VIEW_YT_CREATOR_DATA_ROUTE_ID,
	VIEWERS_PAGE_ROUTE_ID,
} from 'root/src/shared/descriptions/routes/routeIds'
import { rewardEndpointIds } from 'root/src/shared/rewards/constants'
import { assigneePlatformProp, idProp } from 'root/src/shared/util/commonLenses'
import { renameKeys } from 'root/src/shared/util/ramdaPlus'

const { pathOrFormInputs } = formStoreLenses

export const createProjectFormStepKeys = {
	root: 'root',
	payment: 'payment',
	setGoal: 'setGoal',
	productDares: 'productDares',
	paymentBrand: 'paymentBrand',
}

const { root, payment, setGoal, productDares, paymentBrand } = createProjectFormStepKeys

const defaultRoute = {
	routeId: VIEWERS_PAGE_ROUTE_ID,
	query: { modal: DARE_CREATE_SUCCESS_MODULE_ID },
}

const paymentRoute = {
	default: [
		defaultRoute,
		{
			routeId: VIEWERS_PAGE_ROUTE_ID,
			query: { modal: CREATE_DARE_SKIP_PLEDGE_SUCCESS_MODULE_ID },
		},
	],
	[VIEW_CREATOR_DATA_ROUTE_ID]: [
		{
			routeId: VIEW_CREATOR_DATA_ROUTE_ID,
			query: { modal: DARE_CREATE_SUCCESS_MODULE_ID },
		},
		{
			routeId: VIEW_CREATOR_DATA_ROUTE_ID,
			query: { modal: CREATE_DARE_SKIP_PLEDGE_SUCCESS_MODULE_ID },
		},
	],
	[VIEW_YT_CREATOR_DATA_ROUTE_ID]: [
		{
			routeId: VIEW_YT_CREATOR_DATA_ROUTE_ID,
			query: { modal: DARE_CREATE_SUCCESS_MODULE_ID },
		},
		{
			routeId: VIEW_YT_CREATOR_DATA_ROUTE_ID,
			query: { modal: CREATE_DARE_SKIP_PLEDGE_SUCCESS_MODULE_ID },
		},
	],
	[VIEW_TIKTOK_CREATOR_DATA_ROUTE_ID]: [
		{
			routeId: VIEW_TIKTOK_CREATOR_DATA_ROUTE_ID,
			query: { modal: DARE_CREATE_SUCCESS_MODULE_ID },
		},
		{
			routeId: VIEW_TIKTOK_CREATOR_DATA_ROUTE_ID,
			query: { modal: CREATE_DARE_SKIP_PLEDGE_SUCCESS_MODULE_ID },
		},
	],
	[VIEW_FACEBOOK_CREATOR_DATA_ROUTE_ID]: [
		{
			routeId: VIEW_FACEBOOK_CREATOR_DATA_ROUTE_ID,
			query: { modal: DARE_CREATE_SUCCESS_MODULE_ID },
		},
		{
			routeId: VIEW_FACEBOOK_CREATOR_DATA_ROUTE_ID,
			query: { modal: CREATE_DARE_SKIP_PLEDGE_SUCCESS_MODULE_ID },
		},
	],
	[BRAND_DARES_ROUTE_ID]: [
		{
			routeId: BRAND_DARES_ROUTE_ID,
			query: { modal: DARE_CREATE_SUCCESS_MODULE_ID },
		},
		{
			routeId: BRAND_DARES_ROUTE_ID,
			query: { modal: CREATE_DARE_SKIP_PLEDGE_SUCCESS_MODULE_ID },
		},
	],
}

const routeMap = {
	[payment]: paymentRoute,
	[paymentBrand]: paymentRoute,
	[productDares]: {
		default: [defaultRoute],
		[BRAND_DARES_ROUTE_ID]: [
			{
				routeId: BRAND_DARES_ROUTE_ID,
				query: { modal: DARE_CREATE_SUCCESS_MODULE_ID },
			},
		],
	},
	[setGoal]: {
		default: [defaultRoute],
		[VIEW_CREATOR_DATA_ROUTE_ID]: [
			{
				routeId: VIEW_CREATOR_DATA_ROUTE_ID,
				query: { modal: SELF_DARE_CREATE_SUCCESS_MODULE_ID },
			},
		],
		[VIEW_YT_CREATOR_DATA_ROUTE_ID]: [
			{
				routeId: VIEW_YT_CREATOR_DATA_ROUTE_ID,
				query: { modal: SELF_DARE_CREATE_SUCCESS_MODULE_ID },
			},
		],
		[VIEW_TIKTOK_CREATOR_DATA_ROUTE_ID]: [
			{
				routeId: VIEW_TIKTOK_CREATOR_DATA_ROUTE_ID,
				query: { modal: SELF_DARE_CREATE_SUCCESS_MODULE_ID },
			},
		],
		[VIEW_FACEBOOK_CREATOR_DATA_ROUTE_ID]: [
			{
				routeId: VIEW_FACEBOOK_CREATOR_DATA_ROUTE_ID,
				query: { modal: SELF_DARE_CREATE_SUCCESS_MODULE_ID },
			},
		],
	},
}

const onSuccessRedirectSelector = (
	state, { previousRouteId, previousRecordId, stepId, submitIndex, body },
) => {
	const routes = routeMap[stepId][previousRouteId] || routeMap[stepId].default
	const { routeId, query } = routes[submitIndex]

	// tracks donation when it hit default route (former DARE_CREATE_SUCCESS_ROUTE_ID)
	if (isNil(routeMap[stepId].default) && submitIndex) {
		addTrack('Donate')
	}

	const projectUrl = prop('projectUrl', body) || {}
	return ({
		routeId,
		routeParams: { recordId: previousRecordId || projectUrl },
		query,
	})
}

const nextStepIdSelectorFn = (state, moduleKey) => {
	const getPlatformUserId = platform => pipe(
		getTokens,
		find(pipe(
			assigneePlatformProp,
			equals(platform),
		)),
		prop('platformId'),
	)(state)

	const twitchUserId = getPlatformUserId('twitch')
	const ytUserId = getPlatformUserId('youtube')

	const isAssignee = assignee => equals(twitchUserId, assignee) || equals(ytUserId, assignee)

	const isAssigneeObj = pipe(
		pathOrFormInputs(moduleKey, {}),
		propOr([], assigneesKey),
		find(pipe(
			idProp,
			isAssignee,
		)),
	)(state)

	if (isAssigneeObj) {
		return setGoal
	}

	const isBrandUser = pipe(
		userGroupsSelector,
		includes('brand'),
	)(state)

	const paymentType = pipe(
		pathOrFormInputs(moduleKey, {}),
		prop('paymentType'),
	)(state)

	if (equals(paymentType, paymentTypes.brandProduct)) {
		return productDares
	}

	return isBrandUser ? paymentBrand : payment
}

const paymentSubmits = {
	submits: [
		{
			customSubmitType: 'paypalButton',
			endpointId: CREATE_PROJECT,
			onSuccessRedirectSelector,
		},
		{
			label: 'Skip Pledge',
			endpointId: CREATE_PROJECT,
			buttonType: secondaryButton,
			onSuccessRedirectSelector,
		},
	],
}

export default {
	[CREATE_PROJECT_FORM_MODULE_ID]: {
		moduleType: 'stepForm',
		recordType: paymentMethod,
		dropdownAsyncOptions: [
			{
				endpointId: GET_DROP_EVENTS,
				payload: {
					section: userLiveEventsKey,
					pageItemLength: 100,
				},
				modifyFn: pipe(
					prop('items'),
					when(
						is(Array),
						// renaming: "campaignName" for dropdown title, "id" for endpoint schema
						map(renameKeys({ campaignName: 'name', id: 'campaignId' })),
					),
				),
			},
			{ endpointId: rewardEndpointIds.GET_BRAND_REWARDS },
		],
		nextStepIdSelectorFn,
		schema: pipe(
			assoc('required',
				without(
					['paymentInfo'],
					view(lensProp('required'), createProjectPayloadSchema),
				)),
			dissocPath(['additionalProperties']),
			dissocPath(['properties', 'paymentInfo', 'paymentData']),
			dissocPath(['properties', 'paymentInfo', 'paymentType']),
			dissocPath(['properties', 'projectId']),
		)(createProjectPayloadSchema),
		onEnterThunk: autoPopulateDare,
		steps: {
			[root]: {
				title: 'Dare a Creator',
				hasNextStep: true,
				fields: [
					{
						inputType: 'variantField',
						optionsPromiseType: 'twitchChannels',
						fieldId: assigneesKey,
						maxItems: 10,
						placeholder: 'Select',
						uniqueItems: true,
						labelFieldText: [
							{
								text: 'Select Creators',
							},
						],
						required: true,
						isMulti: true,
						variantOptions: [
							{
								name: 'Twitch',
								id: 'twitch',
								inputType: 'autoComplete',
								optionsPromiseType: 'twitchChannels',
								debounceDelay: youtubeSearchDebounce,
							},
							{
								name: 'YouTube',
								id: 'youtube',
								debounceDelay: youtubeSearchDebounce,
								inputType: 'autoComplete',
								optionsPromiseType: 'youtubeChannels',
							},
							{
								name: 'TikTok',
								id: 'tiktok',
								debounceDelay: youtubeSearchDebounce,
								inputType: 'autoComplete',
								optionsPromiseType: 'tiktokCreators',
							},
						],
					},
					{
						fieldId: 'title',
						inputType: 'text',
						placeholder: 'Deagle Monster',
						labelFieldText: [
							{
								text: 'Dare Title',
							},
						],
						required: true,
						inputMaxLength: 60,
					},
					{
						fieldId: 'description',
						inputType: 'text',
						placeholder: 'Get 7 headshot kills with one deagle clip.\n- with 7 shots, get 7 headshot kills with a desert eagle\n- no reloading between kills',
						multiline: true,
						multilineRows: 4,
						subFieldText: 'Make sure you describe what you want to see in detail so the creator can deliver what you want.',
						labelFieldText: [
							{
								text: 'Description',
							},
						],
						required: true,
					},
					{
						fieldId: 'games',
						maxItems: 1,
						inputType: 'autoComplete',
						optionsPromiseType: 'twitchGames',
						placeholder: 'Select',
						uniqueItems: true,
						labelFieldText: [
							{
								text: 'Select video game',
							},
						],
						required: true,
						isMulti: true,
					},
				],
			},
			[productDares]: {
				title: 'Payment Information',
				fields: insert(
					1,
					{
						fieldId: 'campaign',
						inputType: 'dropdown',
						placeholder: 'Choose Campaign',
						uniqueItems: true,
						labelFieldText: [
							{
								text: 'Select campaign',
							},
						],
						maxItems: 1,
						required: true,
						isMulti: false,
						asyncOptionsStoreKey: GET_DROP_EVENTS,
					},
					productCommonFields,
				),
				submits: [
					{
						label: 'Add Product',
						endpointId: CREATE_PROJECT_PRODUCT,
						buttonType: primaryButton,
						onSuccessRedirectSelector,
					},
				],
			},
			[payment]: {
				...formCommon,
				...paymentSubmits,
			},
			[paymentBrand]: {
				...assoc(
					'fields',
					prepend(multiWayField, prop('fields', formCommon)),
					formCommon,
				),
				...paymentSubmits,
			},
			[setGoal]: {
				...acceptProjectCommon,
				title: 'Create a Funding Goal for your Dare',
				submits: [
					{
						label: 'Set Goal',
						endpointId: CREATE_PROJECT,
						buttonType: outlinedButton,
						onSuccessRedirectSelector: (state) => {
							const onSuccessRouteMap = {
								twitch: {
									routeId: VIEW_CREATOR_DATA_ROUTE_ID,
									query: { modal: SELF_DARE_CREATE_SUCCESS_MODULE_ID },
								},
								youtube: {
									routeId: VIEW_YT_CREATOR_DATA_ROUTE_ID,
									query: { modal: SELF_DARE_CREATE_SUCCESS_MODULE_ID },
								},
								tiktok: {
									routeId: VIEW_TIKTOK_CREATOR_DATA_ROUTE_ID,
									query: { modal: SELF_DARE_CREATE_SUCCESS_MODULE_ID },
								},
								facebook: {
									routeId: VIEW_FACEBOOK_CREATOR_DATA_ROUTE_ID,
									query: { modal: SELF_DARE_CREATE_SUCCESS_MODULE_ID },
								},
							}
							return myProfileOnSuccessRedirectSelector(state, onSuccessRouteMap)
						},
					},
				],
			},
		},
	},
}
