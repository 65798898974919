import cronJobPayloadSchema
	from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/cronJobPayloadSchema'
import {
	MONITOR_PAYPAL_BALANCE_POINTS_PAYOUT,
} from 'root/src/shared/descriptions/endpoints/endpointIds'

export default {
	[MONITOR_PAYPAL_BALANCE_POINTS_PAYOUT]: {
		payloadSchema: cronJobPayloadSchema,
		isCron: true,
	},
}
