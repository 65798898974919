import { createGiveawayEntry } from 'root/src/server/modules/giveaways/endpoints/create-giveaway-entry.endpoint';
import { resolveGiveawaysEndpoint } from 'root/src/server/modules/giveaways/endpoints/resolve-giveaways.endpoint';
import { redeemRewardEndpoint } from 'root/src/server/modules/giveaways/endpoints/redeem-reward.endpoint';
import { createGiveawayEntryCallback } from 'root/src/server/modules/giveaways/endpoints/create-giveaway-entry-callback.endpoint';
import { enterGiveawayEndpoint } from 'root/src/server/modules/giveaways/endpoints/enter-giveaway.endpoint';
import { getUserGiveawaysEndpoint } from 'root/src/server/modules/giveaways/endpoints/get-user-giveaways.endpoint';
import { getUserGiveawayRewardsEndpoint } from 'root/src/server/modules/giveaways/endpoints/get-user-giveaway-rewards.endpoint';
import { getGiveawayEndpoint } from 'root/src/server/modules/giveaways/endpoints/get-giveaway.endpoint';
import { isGiveawayActionDoneEndpoint, } from 'root/src/server/modules/giveaways/endpoints/is-giveaway-action-done.endpoint';
import { sendGiveawaySummariesEndpoint, } from 'root/src/server/modules/giveaways/endpoints/send-giveaway-summaries.endpoint';
import { saveGiveawayTermsEndpoint, } from 'root/src/server/modules/giveaways/endpoints/save-giveaway-terms.endpoint';
import { getUserGiveawayTermsEndpoint, } from 'root/src/server/modules/giveaways/endpoints/get-user-giveaway-terms.endpoint';
import { getUserVouchersEndpoint, } from 'root/src/server/modules/giveaways/endpoints/get-user-vouchers.endpoint';
import { getUserGiveawayStatisticsEndpoint, } from 'root/src/server/modules/giveaways/endpoints/get-user-giveaway-statistics.endpoint';
import { getUserActiveGiveawaySchedulerEndpoint, } from 'root/src/server/modules/giveaways/endpoints/get-user-active-giveaway-scheduler.endpoint';
import { updateUserGiveawaySchedulerEndpoint, } from 'root/src/server/modules/giveaways/endpoints/update-user-giveaway-scheduler.endpoint';
import { getUserAvailableActionsEndpoint, } from 'root/src/server/modules/giveaways/endpoints/get-user-available-actions.endpoint';
import { createGiveawayScheduleEndpoint, } from 'root/src/server/modules/giveaways/endpoints/create-giveaway-schedule.endpoint';
import { getVouchersCountEndpoint, } from 'root/src/server/modules/giveaways/endpoints/get-vouchers-count.endpoint';
import { createGiveawayEndpoint } from './create-giveaway.endpoint';
export const giveawayEndpoints = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, createGiveawayEndpoint), createGiveawayEntry), resolveGiveawaysEndpoint), createGiveawayEntryCallback), redeemRewardEndpoint), enterGiveawayEndpoint), getUserGiveawaysEndpoint), getUserGiveawayRewardsEndpoint), getGiveawayEndpoint), isGiveawayActionDoneEndpoint), sendGiveawaySummariesEndpoint), saveGiveawayTermsEndpoint), getUserGiveawayTermsEndpoint), getUserVouchersEndpoint), getUserGiveawayStatisticsEndpoint), getUserActiveGiveawaySchedulerEndpoint), updateUserGiveawaySchedulerEndpoint), getUserAvailableActionsEndpoint), createGiveawayScheduleEndpoint), getVouchersCountEndpoint);
