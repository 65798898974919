import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth';
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses';
import { RewardType } from 'root/src/shared/rewards/constants';
export const useRewardsEndpoint = {
    authentication: authenticationTypes.authenticated,
    authenticationRole: userGroups.admin,
    endpointType: otherEndpointType,
    responseSchema: {
        type: 'object',
        properties: {
            rewardId: { type: 'string' },
            rewardType: { type: 'string' },
            items: {
                type: 'array',
                items: {
                    type: 'object',
                    properties: {
                        decryptedReward: { type: 'string' },
                        rewardDescription: { type: 'string' },
                    },
                },
            },
        },
    },
    payloadSchema: {
        type: 'object',
        properties: {
            rewardId: { type: 'string' },
            brandId: { type: 'string' },
            amount: { type: 'number' },
            rewardType: {
                type: 'string',
                enum: [
                    RewardType.KEY,
                    RewardType.CODE,
                    RewardType.BUNDLE,
                ],
            },
        },
        required: ['rewardId', 'rewardType', 'brandId', 'amount'],
        additionalProperties: false,
    },
};
