import lensPath from 'ramda/src/lensPath'
import over from 'ramda/src/over'
import prepend from 'ramda/src/prepend'
import createListStoreKey from 'root/src/client/logic/api/util/createListStoreKey'
import { ADD_BRAND_PRODUCT_ON_SUCCESS } from 'root/src/client/logic/tableItem/actionIds'
import { BRAND_CUSTOM_PRODUCTS_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import { BRAND_PRODUCTS_AND_KEYS_ROUTE_ID } from 'root/src/shared/descriptions/routes/routeIds'
import { rewardEndpointIds } from 'root/src/shared/rewards/constants'

export default {
	[ADD_BRAND_PRODUCT_ON_SUCCESS]: (state, item) => {
		const { id } = item
		const listStoreKey = createListStoreKey(
			{ routeId: BRAND_PRODUCTS_AND_KEYS_ROUTE_ID },
			rewardEndpointIds.GET_BRAND_CUSTOM_REWARDS,
			BRAND_CUSTOM_PRODUCTS_MODULE_ID,
		)
		const result = over(lensPath(['api', 'lists', listStoreKey, 'items']), prepend(id), state)
		return result
	},
}
