import { DROP_LIFECYCLE_GOING_OFF } from 'root/src/shared/descriptions/endpoints/endpointIds'
import cronJobPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/cronJobPayloadSchema'

export const payloadSchema = cronJobPayloadSchema

export default {
	[DROP_LIFECYCLE_GOING_OFF]: {
		isCron: true,
		payloadSchema,
	},
}
