import { MONITOR_GITHUB_PULL_REQUESTS } from 'root/src/shared/descriptions/endpoints/endpointIds'
import cronJobPayloadSchema
	from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/cronJobPayloadSchema'

export default {
	[MONITOR_GITHUB_PULL_REQUESTS]: {
		payloadSchema: cronJobPayloadSchema,
		isCron: true,
	},
}
