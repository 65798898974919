import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth';
import { listEndpointType } from 'root/src/shared/descriptions/endpoints/lenses';
import { dropKey } from 'root/src/shared/descriptions/endpoints/recordTypes';
import { dropKeyRewardsSchema } from 'root/src/shared/rewards/schemas/dropKeyRewards.schema';
import { saveRewardKeysPayloadSchema } from 'root/src/shared/rewards/schemas/saveRewardKeys.payload.schema';
import { VALIDATE_TOKEN_ACCOUNT } from 'root/src/shared/descriptions/middlewares/middlewareIds';
export const saveKeyRewardsEndpoint = {
    authentication: authenticationTypes.authenticated,
    authenticationRole: userGroups.brandUser,
    endpointType: listEndpointType,
    recordType: dropKey,
    payloadSchema: saveRewardKeysPayloadSchema,
    // TODO: move schema here
    responseSchema: dropKeyRewardsSchema,
    middlewares: [VALIDATE_TOKEN_ACCOUNT],
};
