import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth';
import { userDataEndpointType } from 'root/src/shared/descriptions/endpoints/lenses';
import { accountData } from 'root/src/shared/descriptions/endpoints/recordTypes';
import setBrandSettingsPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/setBrandSettingsPayloadSchema';
import setBrandSettingsResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/setBrandSettingsResponseSchema';
import { VALIDATE_TOKEN_ACCOUNT } from 'root/src/shared/descriptions/middlewares/middlewareIds';
import { brandEndpointIds } from 'root/src/shared/brand/brand.constants';
export const payloadSchema = setBrandSettingsPayloadSchema;
export const responseSchema = setBrandSettingsResponseSchema;
export const setBrandSettingsEndpoint = {
    [brandEndpointIds.SET_BRAND_SETTINGS]: {
        authentication: authenticationTypes.authenticated,
        recordType: accountData,
        authenticationRole: userGroups.brandUser,
        endpointType: userDataEndpointType,
        payloadSchema,
        responseSchema,
        middlewares: [VALIDATE_TOKEN_ACCOUNT],
    },
};
