import { SEND_DARE_EXPIRATION_REMINDER } from 'root/src/shared/descriptions/endpoints/endpointIds'

import cronJobPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/cronJobPayloadSchema'

export default {
	[SEND_DARE_EXPIRATION_REMINDER]: {
		payloadSchema: cronJobPayloadSchema,
		isCron: true,
	},
}
