import { shortUrlEndpointIds } from 'root/src/server/modules/shortUrl/shortUrl.fixture';
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth';
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses';
export const getShortUrlStatsEndpoint = {
    [shortUrlEndpointIds.GET_SHORT_URL_STATS]: {
        authentication: authenticationTypes.authenticated,
        authenticationRole: userGroups.admin,
        endpointType: otherEndpointType,
        payload: {
            type: 'object',
            properties: {
                shortUrl: {
                    type: 'string',
                },
            },
            required: ['shortUrl'],
        },
    },
};
