import gt from 'ramda/src/gt'
import isEmpty from 'ramda/src/isEmpty'
import isNil from 'ramda/src/isNil'
import length from 'ramda/src/length'
import omit from 'ramda/src/omit'
import path from 'ramda/src/path'
import prop from 'ramda/src/prop'
import apiRequest from 'root/src/client/logic/api/thunks/apiRequest'
import setStepsCompleted from 'root/src/client/logic/app/actions/setStepsCompleted'
import checkForNotifications from 'root/src/client/logic/app/thunks/checkForNotifications'
import isAgencyUserSelector from 'root/src/client/logic/static/selectors/isAgencyUserSelector'
import isBrandUserSelector from 'root/src/client/logic/static/selectors/isBrandUserSelector'
import { userEventsKey } from 'root/src/shared/constants/keys'
import {
	GET_DROP_EVENTS,
	GET_PAYOUT_METHOD,
	GET_SELF_PROFILE,
	GET_USER_DATA,
} from 'root/src/shared/descriptions/endpoints/endpointIds'
import { rewardEndpointIds } from 'root/src/shared/rewards/constants'
import displayModal from 'root/src/client/logic/modal/actions/displayModal'
import { PUBLISHER_SETTINGS_MODAL_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import { agencyEndpointIds } from 'root/src/shared/agency/agency.constants'

const displayPublisherSettingsModal = () => dispatch => new Promise((resolve) => {
	dispatch(displayModal(PUBLISHER_SETTINGS_MODAL_MODULE_ID, {
		onSuccess: resolve,
		isCloseable: false,
	}))
})

export default ({ context }) => async (dispatch, getState) => {
	const state = getState()
	const isBrandUser = isBrandUserSelector(state)
	const isAgencyUser = isAgencyUserSelector(state)
	if (isBrandUser) {
		const [userDataRes, dropEventsRes, brandProductsRes] = await Promise.all([
			dispatch(apiRequest(GET_USER_DATA)),
			dispatch(apiRequest(
				GET_DROP_EVENTS, { section: userEventsKey, pageItemLength: 1 },
			)),
			dispatch(apiRequest(rewardEndpointIds.GET_BRAND_REWARDS, { limit: 1 })),
			dispatch(apiRequest(agencyEndpointIds.GET_AGENCY_PUBLISHERS)),
		])
		const hasBrandDetails = !isEmpty(omit(['email', 'groups'], path(['body', 'accountData'], userDataRes)))
		const hasDropEvent = isNil(path(['body', 'items'], dropEventsRes))
			? false
			: gt(length(path(['body', 'items'], dropEventsRes)), 0)
		const hasKeysAndProducts = gt(length(prop('body', brandProductsRes)), 0)

		if (!hasBrandDetails) {
			await dispatch(displayPublisherSettingsModal())
		}

		if (context?.modal) {
			dispatch(displayModal(context.modal))
		}

		return dispatch(setStepsCompleted({ hasBrandDetails, hasDropEvent, hasKeysAndProducts }))
	}
	if (isAgencyUser) {
		return Promise.all([
			dispatch(apiRequest(agencyEndpointIds.GET_AGENCY_PUBLISHERS)),
			dispatch(apiRequest(GET_USER_DATA)),
		])
	}
	await Promise.all([
		dispatch(apiRequest(GET_USER_DATA)),
		dispatch(apiRequest(GET_SELF_PROFILE)),
		dispatch(apiRequest(GET_PAYOUT_METHOD)),
	])

	return dispatch(checkForNotifications())
}
