import { COLLECT_CAMPAIGNS_CHARTS_DATA,	TRIGGER_COLLECT_CAMPAIGNS_CHARTS_DATA } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth'
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'

export default {
	[COLLECT_CAMPAIGNS_CHARTS_DATA]: {
		payloadSchema: {
			type: 'object',
			properties: {
				sections: {
					type: 'array',
					items: {
						type: 'string',
					},
				},
			},
			required: ['sections'],
		},
		isCron: true,
	},
	[TRIGGER_COLLECT_CAMPAIGNS_CHARTS_DATA]: {
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.admin,
		endpointType: otherEndpointType,
		payloadSchema: {
			type: 'object',
			properties: {
				dropId: { type: 'string' },
				sections: {
					type: 'array',
					items: {
						type: 'string',
					},
				},
			},
			required: ['dropId'],
		},
	},
}
