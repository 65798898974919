export const CREATE_PROJECT = 'CREATE_PROJECT'
export const CREATE_PROJECT_DROP = 'CREATE_PROJECT_DROP'
export const CREATE_PROJECT_PRODUCT = 'CREATE_PROJECT_PRODUCT'
export const CREATE_PROJECT_MESSAGES = 'CREATE_PROJECT_MESSAGES'
export const GET_PROJECT = 'GET_PROJECT'
export const SAVE_PARTIAL_DARE_FORM = 'SAVE_PARTIAL_DARE_FORM'
export const CLEAR_PARTIAL_FORM_KEYS = 'CLEAR_PARTIAL_FORM_KEYS'
export const UPDATE_PROJECT = 'UPDATE_PROJECT'
export const REJECT_ACTIVE_PROJECT = 'REJECT_ACTIVE_PROJECT'
export const REVIEW_DELIVERY = 'REVIEW_DELIVERY'
export const REVIEW_DELIVERY_BRAND = 'REVIEW_DELIVERY_BRAND'

export const UPDATE_PROFILE = 'UPDATE_PROFILE'
export const DELETE_PROFILE_BACKGROUND = 'DELETE_PROFILE_BACKGROUND'
export const GET_PROFILE = 'GET_PROFILE'
export const GET_SELF_PROFILE = 'GET_SELF_PROFILE'

export const SET_REDIRECT_URL = 'SET_REDIRECT_URL'

export const GET_PROJECT_BRAND = 'GET_PROJECT_BRAND'

export const ACCEPT_PROJECT = 'ACCEPT_PROJECT'
export const REJECT_PROJECT = 'REJECT_PROJECT'

export const PLEDGE_PROJECT = 'PLEDGE_PROJECT'

export const AUDIT_FAVORITES = 'AUDIT_FAVORITES'
export const AUDIT_FOLLOW_CREATOR = 'AUDIT_FOLLOW_CREATOR'

export const AUDIT_PROJECT = 'AUDIT_PROJECT'

export const GET_PENDING_DELIVERIES = 'GET_PENDING_DELIVERIES'

export const REMOVE_AUTH_TOKEN = 'REMOVE_AUTH_TOKEN'

export const GET_PAYMENT_METHOD = 'GET_PAYMENT_METHOD'
export const GET_PAYMENT_METHODS = 'GET_PAYMENT_METHODS'

export const DELIVERY_DARE = 'DELIVERY_DARE'

export const ADD_PAYOUT_METHOD = 'ADD_PAYOUT_METHOD'
export const GET_PAYOUT_METHOD = 'GET_PAYOUT_METHOD'
export const UPDATE_PAYOUT_METHOD = 'UPDATE_PAYOUT_METHOD'

export const CLEAR_PROJECT_RECORDS = 'CLEAR_PROJECT_RECORDS'
export const COPY_DB_RECORDS = 'COPY_DB_RECORDS'

export const VIEWED_NOTIFICATION = 'VIEWED_NOTIFICATION'
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION'
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS'

export const ADMIN_GET_BANNED_ACCOUNTS = 'ADMIN_GET_BANNED_ACCOUNTS'
export const ADMIN_UNBAN_ACCOUNT = 'ADMIN_UNBAN_ACCOUNT'

export const GET_PAYOUT_ADMIN = 'GET_PAYOUT_ADMIN'

export const GET_SHORT_URL_REPORT = 'GET_SHORT_URL_REPORT'

export const VERIFY_ACCOUNT = 'VERIFY_ACCOUNT'

export const ADD_TOKEN = 'ADD_TOKEN'

export const COLLECT_CREATOR_FOLLOWER_COUNT = 'COLLECT_CREATOR_FOLLOWER_COUNT'
export const UPDATE_CREATORS_SITEMAP = 'UPDATE_CREATORS_SITEMAP'
export const GET_CREATOR_DATA = 'GET_CREATOR_DATA'
export const GET_TOP_CONTRIBUTORS = 'GET_TOP_CONTRIBUTORS'
export const GET_PROJECTS = 'GET_PROJECTS'

export const ADD_COUPON = 'ADD_COUPON'
export const GET_COUPONS = 'GET_COUPONS'
export const UPDATE_COUPON = 'UPDATE_COUPON'
export const DELETE_COUPON = 'DELETE_COUPON'
export const GET_COUPON_BY_NAME = 'GET_COUPON_BY_NAME'
export const ADD_COUPON_TO_USER = 'ADD_COUPON_TO_USER'
export const GET_USER_COUPON_BY_NAME = 'GET_USER_COUPON_BY_NAME'

export const ADD_COMMENT = 'ADD_COMMENT'
export const GET_COMMENTS = 'GET_COMMENTS'
export const GET_COMMENT_REPLIES = 'GET_COMMENT_REPLIES'
export const DELETE_COMMENT = 'DELETE_COMMENT'
export const ADD_COMMENT_REPLY = 'ADD_COMMENT_REPLY'

export const ADD_FEATURED_DARE = 'ADD_FEATURED_DARE'
export const DELETE_FEATURED_DARE = 'DELETE_FEATURED_DARE'
export const PLEDGE_PROJECT_EXTERNAL = 'PLEDGE_PROJECT_EXTERNAL'
export const TWITCH_STREAM_INIT = 'TWITCH_STREAM_INIT'
export const TWITCH_STREAM_END = 'TWITCH_STREAM_END'
export const USER_UPDATE_TWITCH = 'USER_UPDATE_TWITCH'

export const OBS_AUDIO_ALERT = 'OBS_AUDIO_ALERT'
export const GET_ALERTS_URL = 'GET_ALERTS_URL'
export const PAYOUT_PROJECT_TO_CAPTURE = 'PAYOUT_PROJECT_TO_CAPTURE'
export const GET_UPLOAD_SIGNED_URL = 'GET_UPLOAD_SIGNED_URL'
export const SET_ALERTS_CONFIG = 'SET_ALERTS_CONFIG'
export const GET_ALERTS_CONFIG = 'GET_ALERTS_CONFIG'
export const AUDIT_COMMENT_LIKES = 'AUDIT_COMMENT_LIKES'
export const GET_USER_DATA = 'GET_USER_DATA'

export const GET_BRAND_SETTINGS = 'GET_BRAND_SETTINGS'

export const GET_SHORT_TERM_AUTH_TOKEN = 'GET_SHORT_TERM_AUTH_TOKEN'

export const SET_TWITCH_OVERLAY_CONFIG = 'SET_TWITCH_OVERLAY_CONFIG'
export const GET_TWITCH_OVERLAY_CONFIG = 'GET_TWITCH_OVERLAY_CONFIG'

export const SET_OBS_TOP_DARES_CONFIG = 'SET_OBS_TOP_DARES_CONFIG'
export const GET_OBS_TOP_DARES_CONFIG = 'GET_OBS_TOP_DARES_CONFIG'
// fallback for extension version transition
export const GET_DARES_TWITCH = 'GET_DARES_TWITCH'

export const GET_TWITCH_BANNED_USERS = 'GET_TWITCH_BANNED_USERS'
export const SEARCH_TWITCH_GAMES = 'SEARCH_TWITCH_GAMES'
export const SEARCH_TWITCH_GAMES_ADMIN = 'SEARCH_TWITCH_GAMES_ADMIN'
export const SEARCH_TWITCH_USERS = 'SEARCH_TWITCH_USERS'
export const GET_TWITCH_USER_DATA = 'GET_TWITCH_USER_DATA'
export const GET_PENDING_BRAND_USERS = 'GET_PENDING_BRAND_USERS'

export const ADD_CUSTOM_SOUND = 'ADD_CUSTOM_SOUND'
export const DELETE_CUSTOM_SOUND = 'DELETE_CUSTOM_SOUND'
export const GET_CUSTOM_SOUNDS = 'GET_CUSTOM_SOUNDS'

// this is normal endpoint, not a test one
export const TEST_ALERT = 'TEST_ALERT'

export const GET_IS_ALERTS_ACTIVE = 'GET_IS_ALERTS_ACTIVE'
export const GET_CREATORS = 'GET_CREATORS'
export const GET_TOP_ACTIVE_CREATORS = 'GET_TOP_ACTIVE_CREATORS'
export const GET_CREATORS_WHO_ONBOARDING_STUCK = 'GET_CREATORS_WHO_ONBOARDING_STUCK'
export const UPDATE_CUSTOM_SOUND_NAME = 'UPDATE_CUSTOM_SOUND_NAME'
export const GET_TWITCH_PROFILES = 'GET_TWITCH_PROFILES'
export const DROP_ADD_EVENT = 'DROP_ADD_EVENT'
export const DROP_EDIT_EVENT = 'DROP_EDIT_EVENT'
export const DROP_ACTIVATE_EVENT = 'DROP_ACTIVATE_EVENT'
export const DROP_SEND_PLATINUM_EMAIL = 'DROP_SEND_PLATINUM_EMAIL'
export const ADMIN_GENERATE_PAYMENT_LINK = 'ADMIN_GENERATE_PAYMENT_LINK'
export const GET_DROP_EVENTS = 'GET_DROP_EVENTS'
export const DROP_ADMIN_AUDIT = 'DROP_ADMIN_AUDIT'
export const DROP_ACTIVATION_AUDIT = 'DROP_ACTIVATION_AUDIT'
export const SEND_FIRST_DROP_DARE_EMAIL = 'SEND_FIRST_DROP_DARE_EMAIL'
export const CHECK_BRAND_ACTIVE_CAMPAIGN = 'CHECK_BRAND_ACTIVE_CAMPAIGN'
export const SET_EMAIL = 'SET_EMAIL'
export const VERIFY_NEW_EMAIL = 'VERIFY_NEW_EMAIL'
export const NIGHTBOT_AUTH = 'NIGHTBOT_AUTH'
export const NIGHTBOT_COMMAND_HANDLER = 'NIGHTBOT_COMMAND_HANDLER'
export const CHECK_CLIP_AND_DELIVER = 'CHECK_CLIP_AND_DELIVER'
export const GET_BRAND_CODES = 'GET_BRAND_CODES'
export const JOIN_GIVEAWAY = 'JOIN_GIVEAWAY'
export const START_GIVEAWAY = 'START_GIVEAWAY'
export const FINISH_GIVEAWAY = 'FINISH_GIVEAWAY'
export const DROP_SUBSCRIPTION_AUDIT = 'DROP_SUBSCRIPTION_AUDIT'
export const GET_DROP_GAME_DATA = 'GET_DROP_GAME_DATA'
export const REPORT_CTA_CLICK = 'REPORT_CTA_CLICK'
export const BAN_USER_IP = 'BAN_USER_IP'
export const UNBAN_USER_IP = 'UNBAN_USER_IP'
export const ADMIN_GET_TOKEN = 'ADMIN_GET_TOKEN'
export const CHANGE_CAMPAIGN_STATUS = 'CHANGE_CAMPAIGN_STATUS'
export const SET_USER_NOTIFICATION_SETTINGS = 'SET_USER_NOTIFICATION_SETTINGS'
export const HANDLE_PENDING_REVIEW_NOTIFICATIONS = 'HANDLE_PENDING_REVIEW_NOTIFICATIONS'
export const HANDLE_BRAND_REVIEW_EMAIL = 'HANDLE_BRAND_REVIEW_EMAIL'
export const GET_USER_NOTIFICATION_SETTINGS = 'GET_USER_NOTIFICATION_SETTINGS'
export const CREATE_POINTS_PAYOUT = 'CREATE_POINTS_PAYOUT'
export const ADMIN_CAMPAIGN_CREATORS_REPORT = 'ADMIN_CAMPAIGN_CREATORS_REPORT'
export const GET_CREATOR_CAMPAIGN_POINTS = 'GET_CREATOR_CAMPAIGN_POINTS'
export const UPDATE_CREATOR_CAMPAIGN_POINTS = 'UPDATE_CREATOR_CAMPAIGN_POINTS'
export const GET_SIGNED_UP_CREATORS = 'GET_SIGNED_UP_CREATORS'
export const UPDATE_CAMPAIGN_DEAL_ID = 'UPDATE_CAMPAIGN_DEAL_ID'
export const CHECK_FOR_NOTIFICATIONS = 'CHECK_FOR_NOTIFICATIONS'
export const ADD_WHITELISTED_CREATORS = 'ADD_WHITELISTED_CREATORS'
export const GET_WHITELISTED_CREATORS = 'GET_WHITELISTED_CREATORS'
export const REMOVE_WHITELISTED_CREATOR = 'REMOVE_WHITELISTED_CREATOR'
export const REMOVE_DARE = 'REMOVE_DARE'
export const DELETE_DROP_EVENT = 'DELETE_DROP_EVENT'
export const GET_PREVIOUS_DARES = 'GET_PREVIOUS_DARES'
export const INIT_SIGNED_UP_CREATORS_REPORT = 'INIT_SIGNED_UP_CREATORS_REPORT'
export const GENERATE_SIGNED_UP_CREATORS_REPORT = 'GENERATE_SIGNED_UP_CREATORS_REPORT'
export const RESET_CAMPAIGN_DARES = 'RESET_CAMPAIGN_DARES'
export const GET_DROP_SHORT_LINKS_MAPPING_REPORT = 'GET_DROP_SHORT_LINKS_MAPPING_REPORT'

// cloudWatchEvents endpoints
export const PAYOUT_DELAYED_PAYOUTS = 'PAYOUT_DELAYED_PAYOUTS'
export const PAY_OUTSTANDING_PAYOUTS = 'PAY_OUTSTANDING_PAYOUTS'
export const TWITCH_CHAT_SEND_CYCLIC_MESSAGE = 'TWITCH_CHAT_SEND_CYCLIC_MESSAGE'
export const SEND_DARE_EXPIRATION_REMINDER = 'SEND_DARE_EXPIRATION_REMINDER'
export const CLEANUP_DB_BACKUPS = 'CLEANUP_DB_BACKUPS'
export const DARE_EXPIRATION_ACTIONS_RUNNER = 'DARE_EXPIRATION_ACTIONS_RUNNER'
export const DROP_LIFECYCLE_GOING_LIVE = 'DROP_LIFECYCLE_GOING_LIVE'
export const DROP_LIFECYCLE_GOING_OFF = 'DROP_LIFECYCLE_GOING_OFF'
export const REMOVE_STALE_USERS = 'REMOVE_STALE_USERS'
export const COLLECT_DROP_EVENT_DATA = 'COLLECT_DROP_EVENT_DATA'
export const REFRESH_NIGHTBOT_TOKENS = 'REFRESH_NIGHTBOT_TOKENS'
export const COLLECT_SHORT_URL_DATA = 'COLLECT_SHORT_URL_DATA'
export const BROKEN_LINKS_CRAWLER = 'BROKEN_LINKS_CRAWLER'
export const GET_DARES_REPORT = 'GET_DARES_REPORT'
export const MONITOR_TWITCH_CLIPS = 'MONITOR_TWITCH_CLIPS'
export const UPDATE_DELIVERY_RATIO = 'UPDATE_DELIVERY_RATIO'
export const GET_POINTS_MARKETPLACE_ITEMS = 'GET_POINTS_MARKETPLACE_ITEMS'
export const MANAGE_MARKETPLACE_ITEM = 'MANAGE_MARKETPLACE_ITEM'
export const UPDATE_DROP_MNEMONIC_ID = 'UPDATE_DROP_MNEMONIC_ID'
/*
	Twitch panel joining giveaway flow (obfuscated)
	NEWSLETTER = GIVEAWAY
 */

export const JOIN_GIVEAWAY_ALERT = 'JOIN_NEWSLETTER_ALERT'
export const GET_ACTIVE_GIVEAWAY = 'GET_ACTIVE_NEWSLETTER'
export const JOIN_GIVEAWAY_FROM_PANEL = 'JOIN_NEWSLETTER'

export const REQUEST_DROP_EVENT_INVOICE = 'REQUEST_DROP_EVENT_INVOICE'
export const SEND_MONTHLY_INVOICE = 'SEND_MONTHLY_INVOICE'
export const SET_CREATORS_TIERS = 'SET_CREATORS_TIERS'
export const PAYPAL_DROP_EVENT_PAYMENT = 'PAYPAL_DROP_EVENT_PAYMENT'
export const GET_PANEL_SETTINGS = 'GET_PANEL_SETTINGS'
export const SET_PANEL_SETTINGS = 'SET_PANEL_SETTINGS'
export const SET_DARE_PINNED = 'SET_DARE_PINNED'
export const SEND_LOW_KEYS_EMAILS = 'SEND_LOW_KEYS_EMAILS'
export const ADD_AGENCY_PUBLISHER = 'ADD_AGENCY_PUBLISHER'
export const GET_AGENCY_PUBLISHERS = 'GET_AGENCY_PUBLISHERS'
export const GET_AGENCY_PUBLISHERS_LIST = 'GET_AGENCY_PUBLISHERS_LIST'
export const GET_PUBLISHER_TOKEN = 'GET_PUBLISHER_TOKEN'
export const INVITE_AGENCY_ACCOUNT_MANAGER = 'INVITE_AGENCY_ACCOUNT_MANAGER'
export const CONFIRM_TOKEN_USER = 'CONFIRM_TOKEN_USER'
export const GET_AGENCY_ACCOUNT_MANAGERS = 'GET_AGENCY_ACCOUNT_MANAGERS'
export const DELETE_AGENCY_ACCOUNT_MANAGER = 'DELETE_AGENCY_ACCOUNT_MANAGER'
export const GET_DROP_EVENT_DETAILS = 'GET_DROP_EVENT_DETAILS'
export const MANAGE_PUBLISHER_ACCOUNT = 'MANAGE_PUBLISHER_ACCOUNT'
export const ADD_TO_SEND_GRID_LIST = 'ADD_TO_SEND_GRID_LIST'
export const MODIFY_GAME_PLATFORMS = 'MODIFY_GAME_PLATFORMS'
export const SET_PREFERRED_PLATFORM = 'SET_PREFERRED_PLATFORM'
export const CREATOR_API_PROXY = 'CREATOR_API_PROXY'
export const AUTH_API_PROXY = 'AUTH_API_PROXY'
export const GET_CREATOR_POINTS_TRANSACTIONS = 'GET_CREATOR_POINTS_TRANSACTIONS'
/*
	DAREDROP AUTH
 */

export const POST_SIGN_UP = 'POST_SIGN_UP'
export const SIGN_UP_TOKEN = 'SIGN_UP_TOKEN'

export const CHECK_USER_HAS_GAME = 'CHECK_USER_HAS_GAME'
export const LAMBDA_HEALTH_CHECK = 'LAMBDA_HEALTH_CHECK'
export const LAMBDA_HEALTH_CHECK_CRON = 'LAMBDA_HEALTH_CHECK_CRON'

export const MOVE_ACCOUNT_ITEMS = 'MOVE_ACCOUNT_ITEMS'
export const ADD_TERMS_ACCEPTED_CAMPAIGN = 'ADD_TERMS_ACCEPTED_CAMPAIGN'

export const GET_CAMPAIGN_TRANSACTIONS = 'GET_CAMPAIGN_TRANSACTIONS'
export const GET_CAMPAIGN_STREAMS = 'GET_CAMPAIGN_STREAMS'

export const GET_TRANSACTIONS = 'GET_TRANSACTIONS'

export const MONITOR_PAYPAL_BALANCE_POINTS_PAYOUT = 'MONITOR_PAYPAL_BALANCE_POINTS_PAYOUT'
export const GET_POINTS_STATISTICS = 'GET_POINTS_STATISTICS'

export const CREATE_TRANSACTION_PAID_OFF_PLATFORM = 'CREATE_TRANSACTION_PAID_OFF_PLATFORM'
export const GET_STREAMS_STATS_REPORT_PAYLOAD = 'GET_STREAMS_STATS_REPORT_PAYLOAD'

export const MONITOR_GITHUB_PULL_REQUESTS = 'MONITOR_GITHUB_PULL_REQUESTS'

export const GITHUB_WEBHOOK_CALLBACK = 'GITHUB_WEBHOOK_CALLBACK'
export const COLLECT_CAMPAIGNS_CHARTS_DATA = 'COLLECT_CAMPAIGNS_CHARTS_DATA'
export const TRIGGER_COLLECT_CAMPAIGNS_CHARTS_DATA = 'TRIGGER_COLLECT_CAMPAIGNS_CHARTS_DATA'
export const RESOLVE_GIVEAWAYS = 'RESOLVE_GIVEAWAYS'

export const GET_PUBLISHER_DASHBOARD_STATISTICS = 'GET_PUBLISHER_DASHBOARD_STATISTICS'

export const GET_DASHBOARD_DARES = 'GET_DASHBOARD_DARES'
export const GET_DASHBOARD_CREATOR_DARES = 'GET_DASHBOARD_CREATOR_DARES'
export const GET_PRIVATE_DROP_EVENT_REPORT_PAYLOAD = 'GET_PRIVATE_DROP_EVENT_REPORT_PAYLOAD'
export const GET_USER_DROP_EVENT_SHORT_LINK = 'GET_USER_DROP_EVENT_SHORT_LINK'
export const SET_VERIFICATION_STATE = 'SET_VERIFICATION_STATE'
export const GET_VERIFICATIONS = 'GET_VERIFICATIONS'
export const GET_VERIFICATION = 'GET_VERIFICATION'
export const GET_USER_DROP_EVENT_LANDING_SHORT_URL = 'GET_USER_DROP_EVENT_LANDING_SHORT_URL'
export const AUTO_APPROVE_DELIVERIES = 'AUTO_APPROVE_DELIVERIES'
export const GET_DROP_MEDIA = 'GET_DROP_MEDIA'
