import { recordEndpointType } from 'root/src/shared/descriptions/endpoints/lenses';
import { dropEvent } from 'root/src/shared/descriptions/endpoints/recordTypes';
import dropEventResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/dropEventResponseSchema';
import getDropEventPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/getDropEventPayloadSchema';
import { authenticationTypes } from 'root/src/shared/constants/auth';
import { dropEndpointIds } from 'root/src/shared/drops/drops.constants';
export const responseSchema = dropEventResponseSchema;
export const payloadSchema = getDropEventPayloadSchema;
export const dropGetEventEndpoint = {
    [dropEndpointIds.DROP_GET_EVENT]: {
        authentication: authenticationTypes.optional,
        endpointType: recordEndpointType,
        recordType: dropEvent,
        responseSchema,
        payloadSchema,
    },
};
