import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth';
import { recordEndpointType } from 'root/src/shared/descriptions/endpoints/lenses';
import { brandProduct } from 'root/src/shared/descriptions/endpoints/recordTypes';
import { addBrandCustomRewardPayloadSchema } from 'root/src/shared/rewards/schemas/addBrandCustomReward.payload.schema';
export const addBrandCustomRewardEndpoint = {
    authentication: authenticationTypes.authenticated,
    authenticationRole: userGroups.brandUser,
    endpointType: recordEndpointType,
    recordType: brandProduct,
    payloadSchema: addBrandCustomRewardPayloadSchema,
    responseSchema: {
        type: 'object',
        properties: {
            id: { type: 'string' },
            name: { type: 'string' },
            cta: { type: 'string' },
            url: { type: 'string' },
            created: { type: 'string' },
            type: { type: 'string' },
            amount: { type: 'number' },
            restrictions: { type: 'string' },
        },
        required: ['id', 'name', 'cta', 'url', 'created'],
        additionalProperties: false,
    },
};
