import pipe from 'ramda/src/pipe'
import filter from 'ramda/src/filter'
import pick from 'ramda/src/pick'
import includes from 'ramda/src/includes'
import values from 'ramda/src/values'
import any from 'ramda/src/any'
import isEmpty from 'ramda/src/isEmpty'
import find from 'ramda/src/find'
import propEq from 'ramda/src/propEq'
import prop from 'ramda/src/prop'
import {
	CAMPAIGN_CREATORS_MODULE_ID, DEMO_CAMPAIGN_CREATORS_MODULE_ID,
} from 'root/src/shared/descriptions/modules/moduleIds'
import { dashboardTableListType } from 'root/src/shared/constants/moduleTypes'
import providers from 'root/src/shared/constants/providers'
import { apiStoreLenses } from 'root/src/client/logic/api/lenses'
import { mnemonicIdKey, displayNameKey } from 'root/src/shared/constants/keys'
import onEnterActionCalls from 'root/src/client/logic/api/thunks/onEnterActionCalls'
import setModuleDataFilter from 'root/src/client/logic/api/actions/setModuleDataFilter'
import {
	VIEW_CREATOR_DATA_ROUTE_ID,
	VIEW_YT_CREATOR_DATA_ROUTE_ID,
	VIEW_TIKTOK_CREATOR_DATA_ROUTE_ID,
} from 'root/src/shared/descriptions/routes/routeIds'
import currentRouteObj from 'root/src/client/logic/route/selectors/currentRouteObj'
import { creator } from 'root/src/shared/descriptions/endpoints/recordTypes'
import { toCompactNotation } from 'root/src/shared/util/formats'
import { getDashboardFilters } from 'root/src/client/web/static/GraphsDashboard/helpers'
import currentPageSelector from 'root/src/client/logic/list/selectors/currentPageSelector'
import { DEMO_DASHBOARD_RECORD_ID } from 'root/src/shared/demoDashboard/demoDashboard.constants'
import { dashboardConstants } from 'root/src/shared/dashboard/dashboard.constants'

const { viewModuleDataFilters } = apiStoreLenses

export const compactCreatorsTableColumnPools = [
	{ label: 'Minutes\nViewed', id: 'minutesViewed', format: toCompactNotation },
	{ label: 'Views', id: 'viewCount', format: toCompactNotation },
	{ label: 'Completed\nDares', id: 'daresCompletedCount', format: toCompactNotation },
]

export const creatorsTableColumnPools = [
	{ label: 'Minutes\nViewed', id: 'minutesViewed', format: toCompactNotation },
	{ label: 'Views', id: 'viewCount', format: toCompactNotation },
	// {
	// 	label: 'Drops\njoined',
	// 	id: 'dropsJoinedCount',
	// 	condition: ({ routeObj }) => routeObj.routeId === CAMPAIGNS_CREATORS_ROUTE_ID,
	// },
	{ label: 'Completed\nDares', id: 'daresCompletedCount', format: toCompactNotation },
]

export default {
	[CAMPAIGN_CREATORS_MODULE_ID]: {
		moduleType: 'list',
		listType: dashboardTableListType,
		pageItemsCount: 50,
		recordType: creator,
		endpointId: [dashboardConstants.GET_DASHBOARD_CREATORS],
		modifyPayload: ({ payload, moduleDataFilter }) => {
			const { mnemonicIds, to, from, sort, sortBy, limit, currentPage } = payload

			return {
				mnemonicIds,
				to,
				from,
				sort,
				sortBy,
				limit,
				currentPage,
				filters: moduleDataFilter,
			}
		},
		listPayload: (state) => {
			const {
				routeParams: { recordId },
			} = currentRouteObj(state)
			const currentPage = currentPageSelector(state, { moduleId: CAMPAIGN_CREATORS_MODULE_ID }) ?? 1
			const mnemonicIds = recordId ? [recordId] : undefined

			const { to, from } = getDashboardFilters({ state, recordId, moduleId: CAMPAIGN_CREATORS_MODULE_ID })
			return {
				sortBy: 'minutesViewed',
				sort: 'DESC',
				mnemonicIds,
				limit: 50,
				from,
				to,
				filters: {
					platform: {
						[providers.twitch]: true,
					},
				},
				currentPage,
			}
		},
		moduleFilters: [
			{
				alwaysOn: true,
				key: 'platform',
				applicableValues: [
					{
						label: 'Twitch',
						id: providers.twitch,
					},
					{
						label: 'YouTube',
						id: providers.youtube,
					},
					{
						label: 'TikTok',
						id: providers.tiktok,
					},
				],
			},
		],
		spacerCellFilters: [
			{
				key: 'ambassadorsOnly',
				applicableValues: [
					{
						toggleable: true,
						label: 'Ambassadors\nOnly',
						id: 'value',
					},
				],
			},
		],
		tableColumns: (state) => {
			const {
				routeParams: { recordId },
			} = currentRouteObj(state)
			const activePlatforms = getPlatformFilters(viewModuleDataFilters(state), recordId)

			if (isEmpty(activePlatforms)) {
				return []
			}

			const columnsPool = [
				{ label: 'Followers', id: 'followerCount', format: toCompactNotation },
				{ label: 'Subscribers', id: 'subscriberCount', format: toCompactNotation },
				{ label: 'Minutes\nStreamed', id: 'minutesStreamed', format: toCompactNotation },
				{ label: 'CCV', id: 'averageViewers' },
				{ label: 'Streams,\nPosts', id: 'streamsPostsCount' },
				{ label: 'Engagements', id: 'engagementCount', format: toCompactNotation },
				{ label: 'Engage\nRate', id: 'engagementRate' },
				{ label: 'Clicks', id: 'clicks', format: toCompactNotation },
				{ label: 'YouTube Views', id: 'youtubeViews', format: toCompactNotation },
				{ label: 'TikTok Views', id: 'tiktokViews', format: toCompactNotation },
				...creatorsTableColumnPools,
			]
			const platformColumns = {
				twitch: [
					'followerCount',
					'minutesViewed',
					'minutesStreamed',
					'averageViewers',
					'streamsPostsCount',
					'daresCompletedCount',
					'clicks',
					'viewCount',
					// 'dropsJoinedCount',
				],
				youtube: [
					'subscriberCount',
					'minutesViewed',
					'minutesStreamed',
					'streamsPostsCount',
					'daresCompletedCount',
					'engagementCount',
					'engagementRate',
					'clicks',
					'youtubeViews',
					// 'dropsJoinedCount',
				],
				tiktok: [
					'followerCount',
					'streamsPostsCount',
					'daresCompletedCount',
					'engagementCount',
					'engagementRate',
					'clicks',
					'tiktokViews',
					// 'dropsJoinedCount',
				],
			}

			return filter(
				({ id }) => {
					const activeColumnSets = pipe(pick(activePlatforms), values)(platformColumns)
					return any(includes(id), activeColumnSets)
				},
				columnsPool,
			)
		},
		highlightRowFn:
			state => ({ record, data }) => {
				const activePlatforms = getPlatformFilters(
					viewModuleDataFilters(state),
				)
				const recordPlatform = pipe(
					find(propEq('mnemonicId', record)),
					prop('platform'),
				)(data)
				return includes(recordPlatform, activePlatforms)
			},
		indicatorColumnType: 'platformsIndicator',
		dataIdentificator: mnemonicIdKey,
		recordDisplayProp: displayNameKey,
		onEnterThunk: onEnterActionCalls,
		onEnterActions: (state) => {
			const {
				routeParams: { recordId },
			} = currentRouteObj(state)

			const { to, from, ambassadorsOnly } = getDashboardFilters({ state, recordId, moduleId: CAMPAIGN_CREATORS_MODULE_ID })

			return [
				{
					action: setModuleDataFilter,
					payloads: [
						{
							moduleId: CAMPAIGN_CREATORS_MODULE_ID,
							filter: 'payloadFilter',
							id: 'from',
							value: from,
						},
						{
							moduleId: CAMPAIGN_CREATORS_MODULE_ID,
							filter: 'payloadFilter',
							id: 'to',
							value: to,
						},
						{
							moduleId: CAMPAIGN_CREATORS_MODULE_ID,
							filter: 'platform',
							id: providers.twitch,
							value: true,
						},
						{
							moduleId: CAMPAIGN_CREATORS_MODULE_ID,
							filter: 'ambassadorsOnly',
							id: 'value',
							value: ambassadorsOnly,
							toggleable: true,
						},
					],
				},
			]
		},
		recordRouteProps: ({ rowData }) => {
			const { mnemonicId, platform } = rowData
			const profileRouteMap = {
				twitch: VIEW_CREATOR_DATA_ROUTE_ID,
				tiktok: VIEW_TIKTOK_CREATOR_DATA_ROUTE_ID,
				youtube: VIEW_YT_CREATOR_DATA_ROUTE_ID,
			}
			return {
				routeId: profileRouteMap[platform],
				routeParams: { recordId: mnemonicId },
			}
		},
		imageProp: 'image',
		imageBorder: row => row.isAmbassador,
	},
}

function getPlatformFilters(moduleDataFilters, recordId) {
	const moduleId = recordId === DEMO_DASHBOARD_RECORD_ID ? DEMO_CAMPAIGN_CREATORS_MODULE_ID : CAMPAIGN_CREATORS_MODULE_ID
	const platforms = moduleDataFilters?.[moduleId]?.platform ?? {}
	return Object.entries(platforms).filter(([, isEnabled]) => isEnabled).map(([platform]) => platform)
}
