import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth';
import { recordEndpointType } from 'root/src/shared/descriptions/endpoints/lenses';
import { dropKey } from 'root/src/shared/descriptions/endpoints/recordTypes';
import { VALIDATE_TOKEN_ACCOUNT } from 'root/src/shared/descriptions/middlewares/middlewareIds';
export const editBrandRewardsEndpoint = {
    authentication: authenticationTypes.authenticated,
    authenticationRole: userGroups.brandUser,
    endpointType: recordEndpointType,
    recordType: dropKey,
    middlewares: [VALIDATE_TOKEN_ACCOUNT],
};
