import { COLLECT_SHORT_URL_DATA } from 'root/src/shared/descriptions/endpoints/endpointIds'
import collectShortUrlDataRequestSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/collectShortUrlDataRequestSchema'

export default {
	[COLLECT_SHORT_URL_DATA]: {
		isCron: true,
		payloadSchema: collectShortUrlDataRequestSchema,
		isLowSpec: true,
	},
}
