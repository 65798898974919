import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses';
import { authenticationTypes } from 'root/src/shared/constants/auth';
import { VALIDATE_TOKEN_ACCOUNT } from 'root/src/shared/descriptions/middlewares/middlewareIds';
import { pointsEndpointIds } from 'root/src/shared/points/points.constants';
export const initPointsPayoutEndpoint = {
    [pointsEndpointIds.INIT_POINTS_PAYOUT]: {
        authentication: authenticationTypes.authenticated,
        endpointType: otherEndpointType,
        payloadSchema: {
            type: 'object',
            properties: {
                payoutCurrency: { type: 'string' },
            },
            required: [],
            additionalProperties: false,
        },
        middlewares: [VALIDATE_TOKEN_ACCOUNT],
    },
};
